import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import CountUp from "react-countup";
// import { Carousel } from "bootstrap";
import Carousel from "../../components/Carousel";
import { useInView } from "framer-motion";
const Partners = (props) => {
  const ref = useRef();

  const isInView = useInView(ref);
  // const [isInViewFirstTime, setIsInViewFirstTime] = useState(false);
  // useEffect(() => {
  //   setIsInViewFirstTime(isInView);
  // }, []);
  const [hover, setHover] = useState(false);
  const serviceImages = [
    {
      label: "Kadri Luminaire",
      image: "kadri.png",
    },
    {
      label: "Naturel confort",
      image: "naturel.png",
    },
    {
      label: "KMY Ascenseurs",
      image: "KMY.png",
    },
    {
      label: "Easy Move",
      image: "easyMove.png",
    },

    {
      label: "TIS Algeria",
      image: "tis.png",
    },
    {
      label: "CAV ROY",
      image: "cavRoy.png",
    },
    {
      label: "El Djarouf",
      image: "djrouf.png",
    },
  ];
  const productImages = [
    {
      label: "Aymen Promotion",
      image: "new/Aymen.webp",
    },
    {
      label: "Aghiles Résidence",
      image: "new/aghiles.webp",
    },
    {
      label: "Hebbache Promotion",
      image: "new/hebbache.webp",
    },
    {
      label: "Ben Mellissa",
      image: "new/Ben_melissa.webp",
    },
    {
      label: "Cherif Othman",
      image: "new/Cherif.webp",
    },

    {
      label: "Sidi Attalah",
      image: "new/Sidi_attallah.webp",
    },
    {
      label: "Damac Plus",
      image: "new/Damac_plus.webp",
    },
    {
      label: "Dest",
      image: "new/Dest.webp",
    },
  ];
  const stats = [
    {
      product: 22,
      service: 17,
    },

    {
      product: 210,
      service: 250,
    },

    {
      product: 18,
      service: 32,
    },
  ];

  // const handleResize = () => {
  //   if (window.innerWidth < 720) {
  //     setIsMobile(true);
  //   } else {
  //     setIsMobile(false);
  //   }
  // };

  // create an event listener
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  // });
  // const [data, setData] = useState(productImages);
  // useEffect(() => {
  //   if (props.variant == "product") {
  //     setData(productImages);
  //   } else {
  //     setData(serviceImages);
  //   }
  // }, []);

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
    const item = lookup.findLast((item) => num >= item.value);
    // return item
    //   ? (num / item.value)
    //       .toFixed(digits)
    //       .replace(regexp, "")
    //       .concat(item.symbol)
    //   : "0";
    return item.symbol;
  }
  // If a user hasn't opted in for recuded motion, then we add the animation

  return (
    <div ref={ref} className="partners">
      <div>
        <div className="title-container">
          {props.variant == "product" ? (
            <p>
              Ils ont opté pour <span>l'innovation avec nous</span>
            </p>
          ) : (
            <p>
              ils ont aussi choisi de<span> NOUS REJOINDRE</span>
            </p>
          )}
        </div>
      </div>
      <div className="container">
        <div
          style={{
            width: "100%",
            // backgroundColor: "red",
            alignItems: "center",
            justifyContent: "center",
          }}
        ></div>

        <Carousel
          images={props.variant == "service" ? serviceImages : productImages}
          setHover={setHover}
          renderItem={({ item }) => {
            return (
              <div className="item-div-carousel">
                <div className="box-carousel">
                  <div className="item-div-carousel-filter" />
                  <div className="box-inner-carousel">
                    <img
                      style={{ filter: hover ? null : "grayscale(100%)" }}
                      src={require(`../../../assets/partners/${item.image}`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="title-carousel-item">
                  <h4>{item.label}</h4>
                </div>
              </div>
            );
          }}
        />
      </div>

      <div className="counters">
        <div className="counter-container">
          <div className="counter-div">
            <div className="counter-img">
              <img
                src={require("../../../assets/location.svg").default}
                alt="landscape"
                style={{
                  width: 48,
                  height: 48,
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "red",
                }}
              />
            </div>
            <h1
              style={{
                fontSize: 60,
                textAlign: "center",
                margin: 0,
                fontWeight: 800,
                color: props.variant == "product" ? "#3fcebe" : "#ff7a48",
              }}
            >
              +
              {props.variant == "product" ? (
                <CountUp
                  style={{ color: "#3fcebe" }}
                  duration={10}
                  end={isInView ? stats[0].product : 0}
                />
              ) : (
                <CountUp
                  style={{ color: "#ff7a48" }}
                  duration={10}
                  end={isInView ? stats[0].service : 0}
                />
              )}
            </h1>
            <h3 style={{ textAlign: "center", margin: 0, fontWeight: 300 }}>
              <span>Willayas</span>
            </h3>
          </div>
          <div className="counter-div">
            <div className="counter-img">
              <img
                src={require("../../../assets/building.svg").default}
                alt="landscape"
                style={{
                  width: 48,
                  height: 48,
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "red",
                }}
              />
            </div>
            <h1
              style={{
                fontSize: 60,
                textAlign: "center",
                margin: 0,
                fontWeight: 800,
                color: props.variant == "product" ? "#3fcebe" : "#ff7a48",
                color: props.variant == "product" ? "#3fcebe" : "#ff7a48",
              }}
            >
              +
              {props.variant == "product" ? (
                <CountUp
                  style={{ color: "#3fcebe" }}
                  duration={10}
                  end={isInView ? stats[1].product : 0}
                />
              ) : (
                <CountUp
                  style={{ color: "#ff7a48" }}
                  duration={10}
                  end={isInView ? stats[1].service : 0}
                />
              )}
            </h1>
            <h3 style={{ textAlign: "center", margin: 0, fontWeight: 300 }}>
              <span>
                {props.variant == "product"
                  ? "Promoteur immobilier"
                  : "Enterprises"}
              </span>
            </h3>
          </div>
          <div className="counter-div">
            <div className="counter-img">
              <img
                src={
                  props.variant == "product"
                    ? require("../../../assets/house.svg").default
                    : require("../../../assets/service-ic.svg").default
                }
                alt="landscape"
                style={{
                  width: 48,
                  height: 48,
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: "red",
                }}
              />
            </div>
            <h1
              style={{
                fontSize: 60,
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",

                margin: 0,
                color: props.variant == "product" ? "#3fcebe" : "#ff7a48",
                fontWeight: 800,
              }}
            >
              +
              {props.variant == "product" ? (
                <CountUp
                  style={{ color: "#3fcebe" }}
                  duration={10}
                  end={isInView ? stats[2].product : 0}
                  suffix={"k"}
                />
              ) : (
                <CountUp
                  style={{ color: "#ff7a48" }}
                  duration={10}
                  end={isInView ? stats[2].service : 0}
                />
              )}
            </h1>
            <h3
              style={{
                textAlign: "center",
                margin: 0,
                fontWeight: 300,
              }}
            >
              <span>
                {props.variant == "product"
                  ? "Appartements disponible"
                  : "Catégories"}
              </span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
